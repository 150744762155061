<template>
  <main class="search-layout" :class="{ 'search-layout--simple': !filters.length }">
    <slot name="before" />

    <section>
      <div class="horiz-container default-grid">
        <div class="search-header default-grid">
          <div class="search-title"><slot name="title" /></div>
          <div class="search-actions">
            <div v-if="$slots.controls" class="search-controls">
              <slot name="controls" :is-mobile="false" />
            </div>
            <BaseButton v-if="createText && createTo" outlined :to="createTo">{{ createText }}</BaseButton>
          </div>
        </div>

        <aside v-if="filters.length" class="filters-sidebar">
          <div class="filter">
            <div class="title-container">
              <h2 class="filter-title"><IconSettingsAdjust class="icon" /> Фильтр</h2>
              <div class="filter-clear-btn" v-if="!isAllClear" @click="clearAll">Очистить <i class="icon nicon-cross"></i></div>
            </div>
            <div class="filter-controls">
              <slot v-for="filter in filters" :key="filter.id" :name="filter.id" v-bind="filter" />
            </div>
          </div>
        </aside>

        <div class="m-filters">
          <div class="m-filters__header">
            <div class="m-filters__title"><slot name="title" /></div>
            <div class="m-filters__actions">
              <FiltersControl v-if="createTo" :to="createTo"><IconPlus class="icon" /></FiltersControl>
              <FiltersControl v-if="filters.length" :count="appliedCount" @click="popupShown = true" />
            </div>
          </div>
          <div v-if="filters.length || $slots.controls" class="m-filters__chips">
            <slot name="controls" :is-mobile="true" />
            <FilterChip v-if="filters.length" v-for="filter in filters" :key="filter.id" :filter="filter" :total="pagination?.total">
              <slot :name="filter.id" :single="true" v-bind="filter" />
            </FilterChip>
            <BaseButton transparent small v-if="!isAllClear" @click="clearAll">Сбросить</BaseButton>
          </div>
        </div>

        <div class="search-list" v-bind="$attrs">
          <slot />

          <div class="no-items" v-if="pagination?.total === 0 && noItemsText">
            <div class="title">{{ noItemsText }}</div>
            <BaseButton v-if="!isAllClear" color-primary big @click="clearAll">Очистить фильтр</BaseButton>
          </div>

          <HybridRenderer v-slot="{ server }">
            <BasePagination v-if="pagination" class="pagination" :pagination="pagination" :server="server" />
          </HybridRenderer>

          <div v-if="seoDescription" class="search-seo">
            <h1 v-if="seoTitle" class="search-seo__title">{{ seoTitle }}</h1>
            <p class="search-seo__desc">{{ seoDescription }}</p>
          </div>
        </div>
      </div>
    </section>

    <slot name="after" />
  </main>
  <Teleport to="body">
    <MobilePopup :shown="popupShown">
      <MobilePopupHeader title="Фильтр" @close="popupShown = false" />
      <div class="horiz-container">
        <slot v-for="filter in filters" :key="filter.id" :name="filter.id" v-bind="filter" />
      </div>
      <Navbar>
        <BaseButton v-if="!isAllClear" transparent small @click="clearAll">Сбросить</BaseButton>
        <BaseButton class="save-btn" color-primary small @click="popupShown = false">Сохранить{{ pagination?.total !== undefined ? ` (${pagination.total})` : '' }}</BaseButton>
      </Navbar>
    </MobilePopup>
  </Teleport>
</template>

<script>
export default { inheritAttrs: false }
</script>

<script setup>
import { IconPlus } from '@tabler/icons-vue'

const props = defineProps({
  filters: {
    type: Array,
    default: []
  },
  pagination: Object,
  seoTitle: String,
  seoDescription: String,
  noItemsText: String,
  createText: String,
  createTo: [Object, String]
})

const isAllClear = computed(() => props.filters.every(f => !f.isClear || f.isClear.value))
const clearAll = () => props.filters.forEach(f => f.clear?.())

const appliedCount = computed(() => props.filters.reduce((c, f) => c += +!f.isClear.value, 0))

defineExpose({ clearAll })

const popupShown = ref(false)
</script>

<style scoped lang="scss">
.search-layout {
  min-height: inherit;

  :deep(.base-banner-section) {
    margin-top: -50px;
    margin-bottom: 50px;
    @media (max-width: 920px) {
      background: var(--color-bg);
      padding-top: 16px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .search-header {
    grid-column: span 12;
    margin-bottom: 32px;

    .search-title {
      flex-shrink: 0;
      grid-column: span 3;
      position: relative;
      z-index: 3;
    }
    .search-actions {
      flex: 1;
      justify-content: space-between;
      grid-column: span 9;
    }
    .search-controls, .search-actions {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 32px;
    }
    @media (max-width: 920px) {
      display: none !important;
    }
  }

  .filters-sidebar {
    grid-column: span 3;
    @media (max-width: 1280px) {
      grid-column: span 4;
    }
    @media (max-width: 1110px) {
      grid-column: span 12;
    }
    @media (max-width: 920px) {
      display: none;
    }
  }
  .sidebar-title {
    margin-bottom: 32px;
  }

  .filter {
    .title-container {
      display: flex;
      align-items: flex-end;
    }
    .filter-title {
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      text-transform: uppercase;
      display: flex;
      gap: 10px;
      align-items: center;
      & .icon {
        color: var(--color-elements-quantery);
        width: 30px;
        height: 30px;
      }
    }
    .filter-clear-btn {
      border: none;
      background: none;
      cursor: pointer;
      user-select: none;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: var(--color-elements-secondary);
      margin-left: 16px;
      display: flex;
      align-items: center;
      .icon {
        font-size: 9px;
        line-height: 9px;
        color: var(--color-elements-tertiary);
        margin-left: 5px;
      }
      &:hover {
        color: var(--color-elements-primary);
        .icon {
          color: var(--color-elements-secondary);
        }
      }
    }
  }

  .m-filters {
    z-index: 99;
    display: none;
    background: var(--color-bg);
    padding: 16px 0 8px;
    grid-column: span 12;
    margin-bottom: 8px;
    border-bottom: 1px solid var(--color-separator);
    &:has(.m-filters__chips) {
      position: sticky;
      top: 12px;
    }
    @media (max-width: 920px) {
      display: block;
    }
    &__header {
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__actions {
      display: flex;
      align-items: center;
      gap: 24px;
    }
    &__chips {
      padding: 0 15px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      overflow-x: auto;
      overflow-y: hidden;
      > :deep(*) {
        flex-shrink: 0;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .search-list {
    grid-column: span 9;
    @media (max-width: 1280px) {
      grid-column: span 8;
    }
    @media (max-width: 1110px) {
      grid-column: span 12;
    }

    .pagination {
      padding: 32px 0;
      @media (max-width: 920px) {
        background: var(--color-bg);
        padding: 0 15px;
        :deep(.btn-page) {
          height: 56px;
        }
      }
    }
  }

  :deep(.become-section) {
    margin-top: 80px;
    @media (max-width: 920px) {
      display: none;
    }
  }

  .search-seo {
    margin-top: 16px;
    padding-top: 48px;
    border-top: 1px solid var(--color-separator);
    @media (max-width: 920px) {
      padding: 26px 15px;
      margin-top: 8px;
      background: var(--color-bg);
      border-top: none;
    }
    &__title {
      font-weight: 900;
      font-size: 36px;
      line-height: 48px;
      color: var(--color-elements-primary);
      margin-bottom: 10px;
      text-transform: uppercase;
      @media (max-width: 920px) {
        font-size: 28px;
        line-height: 34px;
      }
    }
    &__desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-elements-secondary);
      @media (max-width: 920px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &--simple {
    .search-header {
      grid-column: span 9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
    }
    .m-filters {
      padding: 16px 0;
    }
  }

  @media (max-width: 920px) {
    background: var(--color-bg-secondary);
    margin-bottom: -80px;
    padding-bottom: 8px;
    padding-top: 0;

    .horiz-container.default-grid {
      padding: 0;
    }
  }
}

.save-btn {
  flex: 1;
}
</style>
